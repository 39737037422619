'use strict';

/**
 * This script is used all over the site to make it possible to add/remove product to/from Wishlist.
 * The logic in the file is designed to fetch customer Wishlist only once after the page load to mark Tiles and PDPs
 * for the products the ones already in the Wishlist.
 */

var base = require('../product/base');
var createErrorNotification = require('sfra/components/errorNotification');

// These are global variables to store customer wishlist, the URL to fetch it and state if it is fetched already
let customerWishlist, getCustomerWishlistURL, isCustomerWishlistFetched = false, totalProductInTheWishlist = 0;

/**
 * Changes state of the add to wishlist button
 * @param {string} data - data returned from the server's ajax call
 * @param {HTMLElement} $button - Jquery reference to the button that was clicked to add product to the wishlist
 */
function changeIcon(data, $button) {
    /**
     * Local function to switch labels. It is needed as a function since need to use it again in the loop below
     * @param $button
     * @param {("add"|"remove")} currentAction
     * @param {("remove"|"add")} nextAction
     * @private
     */
    function _updateState($button, currentAction, nextAction) {
        // Switch labels
        const $currentLabel = $button.find('[data-js="add-to-wishlist-label"][data-wishlist-label="' + currentAction + '"]');
        const $nextLabel = $button.find('[data-js="add-to-wishlist-label"][data-wishlist-label="' + nextAction + '"]');
        $currentLabel.addClass('is-hidden');
        $nextLabel.removeClass('is-hidden');

        // Toggle href and wishlist-action data values of the button
        $button.attr('data-wishlist-action', nextAction);
    }

    if (data.success || ('failedSinceAlreadyExist' in data && data.failedSinceAlreadyExist === true)) {
        const currentAction = $button.attr('data-wishlist-action');
        const nextAction = currentAction === 'add' ? 'remove' : 'add';

        // Update state of the button clicked
        _updateState($button, currentAction, nextAction);

        // Find other tiles that are the same product with the product just added to the wishlist and update their state
        const $thisTile = $button.parents('[data-js="product-tile"]');
        const pid = $thisTile.data('pid');
        const $allTilesOfThisProduct = $('[data-js="product-tile"][data-pid="' + pid + '"]');
        $allTilesOfThisProduct.each(function (index, tile) {
            // Skip the same tile
            if (tile === $thisTile[0]) {
                return;
            }

            // Update state of the button in other tiles for the same product
            _updateState($(this).find('[data-js="add-to-wishlist"]'), currentAction, nextAction);
        });
    }

    $button.removeAttr('data-in-process');
}

/**
 * Fetches wishlist information for the customer of the session synchronously and stores in the global variable 'customerWishlist'
 */
function fetchCustomerWishlist() {
    if (!getCustomerWishlistURL) {
        const headerWishlistIcon = $('[data-js="wishlist-header-button"]');
        if (headerWishlistIcon.length === 0) {
            return;
        }

        getCustomerWishlistURL = headerWishlistIcon.data('wishlist-getlist-url');

        if (!getCustomerWishlistURL) {
            return;
        }
    }

    $.ajax({
        url: getCustomerWishlistURL,
        type: 'get',
        dataType: 'json',
        async: false,
        success: function (data) {
            if (!data.success) {
                return;
            }

            customerWishlist = data.list;
            totalProductInTheWishlist = customerWishlist.items.length;
        },
        error: function (err) {
        }
    });
    isCustomerWishlistFetched = true;
    setTimeout(function () {
        // $('body').trigger('wishlist:fetched');
        // Had to use plain JS event dispatcher since the event didn't get triggered via Jquery inside this event listener
        window.dispatchEvent(new Event('wishlist:fetched'));
    }, 0);
}

/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove item from wishlist failed
 */
function displayErrorMessage($elementAppendTo, msg) {
    if ($('.remove-from-wishlist-messages').length === 0) {
        $elementAppendTo.append(
            '<div class="remove-from-wishlist-messages "></div>'
        );
    }
    $('.remove-from-wishlist-messages')
        .append('<div class="remove-from-wishlist-alert text-center alert-danger">' + msg + '</div>');

    setTimeout(function () {
        $('.remove-from-wishlist-messages').remove();
    }, 3000);
}


/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.wishlistItemCardsData').data('public-view');
    var listUUID = $('.wishlistItemCardsData').data('uuid');
    var url = $('.wishlistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done(function (data) {
        $('.wishlistItemCards').empty();
        $('body .wishlistItemCards').append(data);

        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(function () {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

module.exports = {
    registerEventListenersForWishlistItemToggle: function () {
        window.addEventListener('wishlist:fetched', function (event) {
            if (!customerWishlist || customerWishlist.items.length === 0){
                return;
            }

            $('[data-js="wishlist-header-button"]').attr('data-wishlist-empty', 'false');
        });

        // Had to use plain JS event listener since the event didn't get triggerred via JQuery inside another event listener.
        window.addEventListener('wishlist:product-toggle', function (event) {
            totalProductInTheWishlist = event.detail.action === 'add' ? ++totalProductInTheWishlist : --totalProductInTheWishlist;

            if (totalProductInTheWishlist > 0) {
                $('[data-js="wishlist-header-button"]').attr('data-wishlist-empty', 'false');
            } else {
                $('[data-js="wishlist-header-button"]').attr('data-wishlist-empty', 'true');
            }
        });

        // Observe removal of products from wishlist in the wishlist page
        if ($('[data-js="wishlist-page"] .wishlistItemCards').length > 0) {
            const WishlistPageDOMMutationObserver = new MutationObserver((mutationList, observer) => {
                for (const mutation of mutationList) {

                    if ($('.wishlist-item').length === 0) {
                        $('[data-js="wishlist-header-button"]').attr('data-wishlist-empty', 'true');
                    }
                }
            });

            // Start observing the wishlist page
            WishlistPageDOMMutationObserver.observe($('[data-js="wishlist-page"] .wishlistItemCards')[0], { attributes: false, childList: true, subtree: true });
        }
    },
    fetchCustomerWishlistOnLoad: function () {
        // Fetch wishlist and store in 'customerWishlist' global variable
        fetchCustomerWishlist();
    },
    markTilesAlreadyInTheWishList: function () {
        if ($('[data-js="product-tile"]').length === 0) {
            return;
        }

        if (!customerWishlist) {
            fetchCustomerWishlist();
        }

        if (!customerWishlist) {
            return;
        }

        customerWishlist.items.forEach(function (wishlistItem) {
            const pid = wishlistItem.pid;
            const $button = $('[data-js="product-tile"][data-pid="'+pid+'"] [data-js="add-to-wishlist"]');
            $button.find('[data-wishlist-label="add"]').addClass('is-hidden');
            $button.find('[data-wishlist-label="remove"]').removeClass('is-hidden');
            $button.attr('data-wishlist-action', 'remove');
            $('[data-js="product-tile-swatch"][data-pid="'+pid+'"]').addClass('in-the-wishlist');
        });
    },
    markRecommendationTilesAlreadyInTheWishlist: function () {
        const $pdpRecommendations = $('[data-js="recommendations"]');
        if ($pdpRecommendations.length === 0) {
            return;
        }

        // Create an observer instance linked to the callback function
        const DOMMutationObserver = new MutationObserver((mutationList, observer) => {
            for (const mutation of mutationList) {
                const $recommendations = $(mutation.target);
                if ($recommendations.find('[data-js="product-tile"]').length === 0) {
                    return;
                }

                if (!isCustomerWishlistFetched) {
                    fetchCustomerWishlist();
                }

                if (!customerWishlist) {
                    return;
                }

                customerWishlist.items.forEach(function (wishlistItem) {
                    const pid = wishlistItem.pid;
                    const $button = $recommendations.find('[data-js="product-tile"][data-pid="'+pid+'"] [data-js="add-to-wishlist"]');
                    if ($button.length > 0) {
                        $button.find('[data-wishlist-label="add"]').addClass('is-hidden');
                        $button.find('[data-wishlist-label="remove"]').removeClass('is-hidden');
                        $button.attr('data-wishlist-action', 'remove');
                        $button.siblings('[data-js="tile-swatches"]').find('[data-js="product-tile-swatch"]').addClass('in-the-wishlist');
                    }
                });

            }
        });

        $pdpRecommendations.each(function () {
            // Start observing the recommendation wrappers
            DOMMutationObserver.observe(this, { attributes: false, childList: true, subtree: true });
        });
    },
    markPDPAlreadyInTheWishList: function () {
        const $productDetail = $('[data-js="product-detail"]');
        if ($productDetail.length === 0) {
            return;
        }

        if (!isCustomerWishlistFetched){
            fetchCustomerWishlist();
        }

        if (!customerWishlist) {
            return;
        }

        const pdpPid = $productDetail.data('pid');
        customerWishlist.items.forEach(function (wishlistItem) {
            const pid = wishlistItem.pid;
            if (parseInt(pid) !== parseInt(pdpPid)) {
                return;
            }

            $productDetail.find('[data-js="add-to-wishlist"] [data-wishlist-label="add"]').addClass('is-hidden');
            $productDetail.find('[data-js="add-to-wishlist"] [data-wishlist-label="remove"]').removeClass('is-hidden');
            $productDetail.find('[data-js="add-to-wishlist"]').attr('data-wishlist-action', 'remove');
            $productDetail.find('.swatch.is-active').addClass('in-the-wishlist');
        });
    },
    addToWishlistToggle: function () {
        $('body').on('click', '[data-js="add-to-wishlist"]', function (e) {
            const $button = $(this);
            if (!!$button.attr('data-in-process')) {
                return;
            }

            const buttonAction = $button.attr('data-wishlist-action');
            const url = $(this).data(buttonAction + '-href');
            const pid = $(this).closest('[data-js="product-tile"]').attr('data-pid') || $(this).closest('[data-js="product-detail"]').attr('data-pid');
            let optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id') || null;
            let optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id') || null;
            if (!url || !pid) {
                return;
            }
            $button.attr('data-in-process', true);

            $.ajax({
                url: url,
                type: buttonAction === 'add' ? 'post' : 'get',
                dataType: 'json',
                async: false,
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    changeIcon(data, $button);
                    if (buttonAction === 'add') {
                        const dataObj = {
                            event: 'addToWishlist',
                            eventCategory: 'User interaction',
                            currencyCode: 'EUR',
                            product: pid.toString()
                        };
                        window.dataLayer.push(dataObj);

                        $button.siblings('[data-js="tile-swatches"]').find('.is-active').closest('[data-js="product-tile-swatch"]').addClass('in-the-wishlist');
                        if ($button.parent().hasClass('product-slider')) {
                            $button.parent().siblings().find('.swatch.is-active').addClass('in-the-wishlist');
                        }
                    } else {
                        const dataObj = {
                            event: 'removeFromWishlist',
                            eventCategory: 'User interaction',
                            currencyCode: 'EUR',
                            product: pid.toString()
                        };
                        window.dataLayer.push(dataObj);
                        $button.siblings('[data-js="tile-swatches"]').find('.is-active').closest('[data-js="product-tile-swatch"]').removeClass('in-the-wishlist');
                        if ($button.parent().hasClass('product-slider')) {
                            $button.parent().siblings().find('.swatch.is-active').removeClass('in-the-wishlist');
                        }
                    }
                },
                error: function (err) {
                    changeIcon(err, $button);
                }
            });

            // Had to use plain JS event dispatcher since the event didn't get triggered via Jquery inside this event listener
            window.dispatchEvent(new CustomEvent('wishlist:product-toggle', {detail: {action : buttonAction}}));
        });
    },
    handleContinueShoppingButton: function () {
        const $continueShopping = $('[data-js="wishlist-page"] [data-js="continue-shopping"]');
        if ($continueShopping.length === 0) {
            return;
        }

        // Update the href value to previous page if the referer page is from this site
        if (document.referrer.indexOf(window.location.origin) === 0) {
            $continueShopping.attr('href', document.referrer);
        }
    },
    handleGTMForAddToCart: function () {
        $('body').on('product:afterAddToCart', function (event, data) {
            if ($('[data-js="wishlist-page"]').length === 0) {
                return;
            }

            var dataObj = {
                event: 'wishlistToCart',
                eventCategory: 'User interaction',
                currencyCode: 'EUR',
                product: data.requestData.pid.toString()
            };
            window.dataLayer.push(dataObj);
        });
    },
    removeFromWishlist: function () {
        $('body').on('click', '[data-js="remove-from-wishlist"]', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var $button = $(this);
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function () {
                    var pageNumber = $('.wishlistItemCardsData').data('page-number') - 1;
                    var pid = $button.closest('.wishlist-item').find('[data-js="product-tile"]').data('pid').toString();
                    renderNewPageOfItems(pageNumber, false);
                    var dataObj = {
                        event: 'removeFromWishlist',
                        eventCategory: 'User interaction',
                        currencyCode: 'EUR',
                        product: pid
                    };
                    window.dataLayer.push(dataObj);
                },
                error: function () {
                    $.spinner().stop();
                    var $elToAppendWL = $('.wishlistItemCards');
                    var msg = $elToAppendWL.data('error-msg');
                    displayErrorMessage($elToAppendWL, msg);
                }
            });
        });
    }
};
