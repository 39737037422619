
'use strict';

import Swiper, {Navigation, Pagination, Scrollbar} from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);

var swiperSlider = new Swiper('.js-swiper', {
   spaceBetween: 10,
   loop: false,
   slidesPerView: 1,
   navigation: {
     nextEl: '.swiper-button-next',
     prevEl: '.swiper-button-prev',
   },
});

/**
 * @function
 * @description Swiper configuration for the Brand Slider Page Designer component
 */
var brandSlider = new Swiper('.js-brand-slider', {
    spaceBetween: 12,
    loop: false,
    slidesPerView: 'auto',
    grabCursor: true,
    centerInsufficientSlides: true,
    observeParents: true,
    scrollbar: {
        el: ".brand-slider__nav > .swiper-scrollbar",
        hide: false,
        draggable: true
    }
});
