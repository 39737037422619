'use strict';

module.exports = function () {
    var fitmixInstance;
    var mainFitmix = $('[data-js="main-fitmix-container"]');
    var fitmix = document.getElementById('fitmix-container');
    var apiKey = mainFitmix.data('fittingbox-api');
    if (mainFitmix && apiKey) {
        var params = {
            apiKey: apiKey,
            onStopVto: () => { fitmix.style.display = 'none'; },
            popupIntegration: {
                centeredHorizontal: true,
                centeredVertical: true,
                width: '100%',
                height: '100%'
            }
        };
    }

    $(document).on('click', '[data-js="tryon-btn"]', function (e) {
        e.preventDefault();
        var glassesId = $(this).data('glasses-id');
        params.frame = glassesId.toString();
        fitmixInstance = FitMix.createWidget('fitmix-container', params, () => {
            fitmix.style.display = 'block';
            fitmixInstance.startVto('live');
        });
    });
};
