'use strict';

var createErrorNotification = require('sfra/components/errorNotification');

// Glass book add to cart button in PDP
$('[data-js="glassbook-show"]').on('click', function (e) {
    var $glassbookShowBtn = $(e.target);
    $('.add-to-cart-not-available-msg').addClass('is-hidden');
    if ($glassbookShowBtn.hasClass('is-disabled')) {
        if ($glassbookShowBtn.data('ptype') === 'master') {
            $('[data-js="color-not-selected-msg"]').removeClass('is-hidden');
        } else {
            $('[data-js="product-not-available-msg"]').removeClass('is-hidden');
        }
        return;
    }

    var productPage = $('.main-page')[0];
    var glassbookPage = $('.glassbook-page')[0];

    if ($('.glassbook-response').hasClass('glassbook-is-set')) {
        if (productPage.style.display === 'none') {
            productPage.style.display = 'block';
            glassbookPage.style.display = 'none';
        } else {
            productPage.style.display = 'none';
            glassbookPage.style.display = 'block';
            $('html, body').animate({scrollTop: '0'}, 100);
        }
        return;
    }
    $.ajax({
        url: $glassbookShowBtn.attr('href'),
        type: 'GET',
        dataType: 'html',
        success: function (response) {
            $('.glassbook-response').html(response);
            productPage.style.display = 'none';
            glassbookPage.style.display = 'block';
            $('.glassbook-response').addClass('glassbook-is-set');
            $('html, body').animate({scrollTop: '0'}, 100);
        },
        error: function () {
        }
    });
});

// Glass book add to cart button in product tile
$('body').on('click', '[data-js="glassbook-show-tile"]', function (event) {
    event.preventDefault();
    const $button = $(this);

    const addToCartUrl = $button.data('url');

    if (!addToCartUrl) {
        return;
    }

    $.ajax({
        url: addToCartUrl,
        type: 'GET',
        dataType: 'html',
        success: function (response) {
            const $modal = $('.glassbook-response');
            $modal.html(response);
            $modal[0].style.display = 'block';
            $('.main-page')[0].style.display = 'none';
            $('html, body').animate({scrollTop: '0'}, 100);
            const pid = $button.closest('[data-js="product-tile"]').data('pid').toString() || $button.closest('[data-js="product-detail"]').data('pid').toString();
            var dataObj = {
                event: 'wishlistToCart',
                eventCategory: 'User interaction',
                currencyCode: 'EUR',
                product: pid
            };
            window.dataLayer.push(dataObj);
        },
        error: function () {
        }
    });
});
