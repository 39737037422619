'use strict';

var processInclude = require('sfra/util');
var newsletterPopup = require('@baseJs/project/components/newsletterPopup');
const cookie = require('../../utils/cookie');

const COOKIE_DIALOG_WRAPPER = 'cookie-dialog-wrapper';
const COOKIE_PREF_NAME = 'em_pref';

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 * @param {boolean} onlyModalForm Decides what is shown
*/
function showConsentModal(onlyModalForm = false) {
    const consentDisabled = $('.js-consent-config').data('consent-disabled') === 'yes';

    if (consentDisabled || !$('#cookie-consent').data('caonline')) {
        triggerNewsletterPopup(); // Trigger immediately if no consent modal is shown
        return;
    }

    var urlContent = $('#cookie-consent').data('url');

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('body').append(`<div id="${COOKIE_DIALOG_WRAPPER}">${response}</div>`);
            if (onlyModalForm) {
                $('.cookie-dialog-modal').addClass('open standalone');
            } else {
                $('.cookie-dialog-screen').addClass('open');
            }
        },
        error: function () {
            triggerNewsletterPopup(); // Trigger if there's an error loading the consent modal
        }
    }).done(function () {
        var $cookieForm = $('.cookie-consent-form');
        var isAllowedConsent = true;

        $(document).off('click', '.cookie-consent-accept');
        $(document).on('click', '.cookie-consent-accept', function (e) {
            e.preventDefault();

            if ($(this).hasClass('btn-disabled')) {
                $('[data-js="set-cookies-not-available-msg"]').removeClass('is-hidden');
                return;
            }

            if ($(this).data('cookies') === 'necessary') {
                setOnlyNecessaryCookies($cookieForm);
            }

            var url = $(this).data('url');

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: getFormData($cookieForm),
                success: function () {
                    const data = getFormData($cookieForm);
                    addDatalayerEvent(data);
                    setCookieWithConsentValue(isAllowedConsent);
                    $('.cookie-dialog-screen').removeClass('open');
                    $('.cookie-dialog-modal').removeClass('open standalone');
                    $(`#${COOKIE_DIALOG_WRAPPER}`).remove();

                    // Trigger newsletter popup after consent modal closes
                    triggerNewsletterPopup();
                },
                error: function () {
                    $('.cookie-dialog-screen').removeClass('open');
                    triggerNewsletterPopup(); // Trigger in case of error in consent submission
                }
            });
        });

        $(document).off('click', '[data-js="cookie-options-all"]');
        $(document).on('click', '[data-js="cookie-options-all"]', function (e) {
            e.preventDefault();
            var $btn = $(e.currentTarget);
            if ($btn.hasClass('active')) {
                return;
            }
            $('[data-js="set-cookies"]').removeClass('btn-disabled');
            $('[data-js="set-cookies-not-available-msg"]').addClass('is-hidden');
            $btn.addClass('active');
            $('[data-js="cookie-options-necessary"]').removeClass('active');
            setAllCookies($cookieForm);
            isAllowedConsent = true;
        });

        $(document).off('click', '[data-js="cookie-options-necessary"]');
        $(document).on('click', '[data-js="cookie-options-necessary"]', function (e) {
            e.preventDefault();
            var $btn = $(e.currentTarget);
            if ($btn.hasClass('active')) {
                return;
            }
            $('[data-js="set-cookies"]').removeClass('btn-disabled');
            $('[data-js="set-cookies-not-available-msg"]').addClass('is-hidden');
            $btn.addClass('active');
            $('[data-js="cookie-options-all"]').removeClass('active');
            setOnlyNecessaryCookies($cookieForm);
            isAllowedConsent = true;
        });
    });
}

function triggerNewsletterPopup() {
    processInclude(newsletterPopup); // This will initialize the newsletter popup logic
}

function setAllCookies($cookieForm) {
    $cookieForm.find('#cookie-select-all').attr('value', 'on');
    $cookieForm.find('#cookie-select-marketing').attr('value', 'on');
    $cookieForm.find('#cookie-select-personalization').attr('value', 'on');
}

function setOnlyNecessaryCookies($cookieForm) {
    $cookieForm.find('#cookie-select-all').attr('value', 'off');
    $cookieForm.find('#cookie-select-marketing').attr('value', 'off');
    $cookieForm.find('#cookie-select-personalization').attr('value', 'off');
}

function getFormData($form) {
    var unindexedArray = $form.serializeArray();
    var indexedArray = {};

    $.map(unindexedArray, function (n) {
        indexedArray[n['name']] = n['value'];
    });

    return indexedArray;
}

function setCookieWithConsentValue(value) {
    const cookieValue = JSON.stringify({
        Consent: value || null,
        Locale: null
    });

    cookie.setCookie(COOKIE_PREF_NAME, cookieValue, 3650);
}

function addDatalayerEvent(data) {
    window.dataLayer.push({
        event: 'consent_update',
        consentData: {
            personalization: data.cookie_select_personalization,
            performance: data.cookie_select_performance,
            marketing: data.cookie_select_marketing,
            all: data.cookie_select_all
        }
    });
}

module.exports = function () {
    if (cookie.getCookie(COOKIE_PREF_NAME) === '') {
        setCookieWithConsentValue();
    }

    if (!$('#cookie-consent').data('consented')) {
        showConsentModal();
    } else {
        triggerNewsletterPopup();
    }

    $('.js-footer-edit-cookies').on('click', (e) => {
        e.preventDefault();
        showConsentModal(true);
    });
};
