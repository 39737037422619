'use strict';

// Page type mapping to actions
const PAGE_MAPPING = {
    plp: ['Search-Show'],
    pdp: ['Product-Show'],
    appointmentFlow: [
        'Appointment-Show', 'Appointment-Info', 'Appointment-Questionnaire',
        'Appointment-StoreSelection', 'Appointment-CustomerData',
        'Appointment-Confirmation'
    ],
    tryInStoreFlow: [
        'TryInStore-StoreSelector', 'TryInStore-CustomerData',
        'TryInStore-Confirmation'
    ],
    cartPage: ['Cart-Show'],
    checkoutPages: ['Checkout-Begin'],
    orderConfirmation: ['Order-Confirm'],
    ltp: [
        'Ltp-Begin', 'Ltp-ExtraOptions', 'Ltp-Overview', 'Ltp-Checkout', 'Ltp-Confirmation'
    ]
};

const selectors = {
    newsletterPopup: '[data-js="modal-newsletter"]',
    mainPage: '[data-js="main-page"]',
    modalCloseButton: '[data-js="modal-newsletter-close"]',
    modalContent: '[data-js="modal-newsletter-content"]'
};

// Elements
const elements = {
    newsletterPopup: $(selectors.newsletterPopup),
    mainPage: $(selectors.mainPage),
    modalCloseButton: $(selectors.modalCloseButton),
    modalContent: $(selectors.modalContent)
};

// Config Variables
const clickThreshold = Number(elements.newsletterPopup.attr('data-click-num'));
const inactivityThreshold = Number(elements.newsletterPopup.attr('data-seconds-num')) * 1000;
const isExitEnabled = elements.newsletterPopup.attr('data-exit-enabled') === "true";

// State Variables
let clickCount = 0;
let inactivityTimeout;
let hasShownPopup = sessionStorage.getItem('newsletterPopupShown') === 'true';
let interceptedElement = null; // Track the intercepted element

// Get current page type and ID from main page data attributes
function getCurrentPageInfo(attribute) {
    return elements.mainPage.attr(attribute);
}

// Function to determine popup visibility
function shouldShowPopup() {
    const excludedActions = JSON.parse(elements.newsletterPopup.attr('data-exclude-pages') || '[]');
    const excludedPDPageIds = JSON.parse(elements.newsletterPopup.attr('data-exclude-pd-ids') || '[]');

    const currentAction = getCurrentPageInfo('data-action');
    const excludedActionValues = excludedActions.flatMap(action => PAGE_MAPPING[action] || []);
    const isExcludedAction = excludedActionValues.includes(currentAction);

    // Extract currentPageId only if the query string contains '='
    const queryString = getCurrentPageInfo('data-querystring');
    const currentPageId = queryString && queryString.includes('=') ? queryString.split('=')[1] : null;
    const isExcludedPageId = excludedPDPageIds.includes(currentPageId);

    return !(isExcludedAction || isExcludedPageId);
}

// Fire GTM event
function fireGtmEvent(eventAction) {
    var data = {
        "event": "popup",
        "eventCategory": "popup",
        "eventAction": eventAction + "_popup"
    };
    window.dataLayer.push(data);
}

// Show the popup if conditions are met
let isOpeningPopup = false; // Flag to indicate if the popup is opening
function showNewsletterPopup() {
    if (shouldShowPopup() && !hasShownPopup) {
        isOpeningPopup = true; // Set the flag to true when opening
        elements.newsletterPopup.addClass('is-open');
        $('body').addClass('scroll-disabled');
        sessionStorage.setItem('newsletterPopupShown', 'true');
        hasShownPopup = true;
        clearTimeout(inactivityTimeout);
        fireGtmEvent('view');

        // Reset the flag after a brief moment to allow the opening animation to finish
        setTimeout(() => {
            isOpeningPopup = false;
        }, 300);
    }
}

// Close the newsletter popup and clear intercepted element
function closeNewsletterPopup() {
    elements.newsletterPopup.removeClass('is-open');
    $('body').removeClass('scroll-disabled');
    fireGtmEvent('close');

    // Clear intercepted element to allow re-click
    interceptedElement = null;
}

// Setup popup triggers
function setupTriggers() {
    // Check if modal content exists before proceeding with any logic
    if (!elements.modalContent.length) {
        return;
    }

    // Trigger to increment click count and check if it meets the threshold
    $(document).on('click', (e) => {
        // Only intercept if click count threshold is met and popup hasn't shown
        if (shouldShowPopup() && !hasShownPopup && ++clickCount >= clickThreshold) {
            e.preventDefault(); // Prevent default action temporarily
            e.stopImmediatePropagation(); // Stop further event handling
            interceptedElement = e.target; // Store the clicked element
            showNewsletterPopup();
        }
    });

    const resetInactivityTimer = () => {
        if (typeof inactivityThreshold === 'number' && inactivityThreshold > 0) {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(() => {
                if (!hasShownPopup) {
                    showNewsletterPopup();
                }
            }, inactivityThreshold);
        };
    };

    // Reset inactivity timer on user interactions
    $(window).on('click mousemove keypress scroll', resetInactivityTimer);

    // Start inactivity timer
    resetInactivityTimer();

    // Event listener for modal close button
    elements.modalCloseButton.on('click', closeNewsletterPopup);

    // Event listener to close modal when clicking outside of .modal-content
    $(document).on('click', (e) => {
        if (elements.newsletterPopup.hasClass('is-open') && $(e.target).is(elements.newsletterPopup) && !isOpeningPopup) {
            closeNewsletterPopup();
        }
    });

    // New event listener to show popup when the mouse leaves the document
    if (isExitEnabled) {
        $(document).on('mouseleave', () => {
            if (!hasShownPopup) {
                showNewsletterPopup();
            }
        });
    }
};

module.exports = {
    onLoad: setupTriggers
};
