'use strict';

//Shows the single purchase popup and loads data for functionality
//Checks if data is already loaded
$('[data-js="lens-show"]').on('click', function (e) {
    var $element = $(this);
    var productPage = $('.main-page')[0];
    var lensePage = $('.lens-page')[0];

    if ($('.lens-response').hasClass('lens-is-set')) {
        if (productPage.style.display === 'none') {
            productPage.style.display = 'block';
            lensePage.style.display = 'none';
        } else {
            productPage.style.display = 'none';
            lensePage.style.display = 'block';
        }
        return;
    }
    $.ajax({
        url: $element.attr('href'),
        type: 'GET',
        dataType: 'html',
        success: function (response) {
            $('.lens-response').html(response);
            productPage.style.display = 'none';
            lensePage.style.display = 'block';
            $('.lens-response').addClass('lens-is-set');
        },
        error: function () {
        }
    });
});

// Lens add to cart button in product tile
$('body').on('click','[data-js="lens-show-tile"]', function (e) {
    var $button = $(this);
    var productPage = $('.main-page')[0];
    var lensePage = $('.lens-page')[0];
    const $modal = $('.lens-response');

    if ($modal.hasClass('lens-is-set')) {
        if (productPage.style.display === 'none') {
            productPage.style.display = 'block';
            lensePage.style.display = 'none';
        } else {
            productPage.style.display = 'none';
            lensePage.style.display = 'block';
        }
        return;
    }

    const addToCartUrl = $button.data('url');

    if (!addToCartUrl) {
        return;
    }

    $.ajax({
        url: addToCartUrl,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $modal.html(response);
            productPage.style.display = 'none';
            lensePage.style.display = 'block';
            $modal.addClass('lens-is-set');
            const pid = $button.closest('[data-js="product-tile"]').data('pid').toString() || $button.closest('[data-js="product-detail"]').data('pid').toString();
            var dataObj = {
                event: 'wishlistToCart',
                eventCategory: 'User interaction',
                currencyCode: 'EUR',
                product: pid
            };
            window.dataLayer.push(dataObj);
        },
        error: function () {
        }
    });
});

// Lent total plan flow start button
$('body').on('click', '[data-js="start-ltp-flow"]', function (e) {
    e.preventDefault();

    var $url = $(this).data('href');

    $.ajax({
        url: $url,
        type: 'GET',
        dataType: 'html',
        success: function () {
            window.location.href = $url;
        },
        error: function () {
        }
    });
});
