'use strict';

const base = require("../product/base");

module.exports = {
    handleAddToCartButton: function () {
        $('body').on('click', 'button.js-add-to-cart-tile', function (event) {
            event.preventDefault();
            const $button = $(this);

            $('body').trigger('product:beforeAddToCart', this);

            const pid = $button.data('pid');
            const addToCartUrl = $button.data('url');

            if (!addToCartUrl) {
                return;
            }

            var formData = {
                pid: pid,
                pidsObj: null,
                childProducts: [],
                quantity: 1
            };

            $(this).trigger('updateAddToCartFormData', formData);
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: formData,
                success: function (data) {
                    base.handlePostCartAdd(data);
                    $button.trigger('product:afterAddToCart', Object.assign(data, {requestData: formData}));
                    base.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {

                }
            });
        });
    }
};
