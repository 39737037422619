/* eslint-disable no-undef */
/* eslint-disable camelcase */

'use strict';

const customOnlineChatButton = '.js-online-chat-button';
const chatButtonWrapper='.minimizedButtonWrapper';
const modal = require('../components/modal');
const livechatModal = $('#livechatModal');

$(document).ready(function() {
    $(chatButtonWrapper).hide();
    const $chatButton = $(customOnlineChatButton);
    if ($chatButton.length) {
        $chatButton.on('click', launchChat);
    }

    // Check session storage to maintain the chat session continuity.
    const startMessaging = sessionStorage.getItem('messagingStartCheck');
    if (startMessaging && startMessaging === 'YES') {
        console.log('Messaging was in progress');
        initEmbeddedMessaging();
    }
});

function initEmbeddedMessaging() {
    const $chatButton = $(customOnlineChatButton);
    const orgId = $chatButton.data('org-id');
    const deploymentId = $chatButton.data('deployment-id');
    const liveChatURL = $chatButton.data('live-chat-url');
    const scrt2URL = $chatButton.data('scrt2-url');

    try {
        if (typeof embeddedservice_bootstrap !== 'undefined') {
            embeddedservice_bootstrap.settings.language = 'nl_NL';
            embeddedservice_bootstrap.init(orgId, deploymentId, liveChatURL, {scrt2URL: scrt2URL});
            if (typeof embeddedservice_bootstrap !== 'undefined' && embeddedservice_bootstrap.utilAPI) {
                $(chatButtonWrapper).show();
            }
        } else {
            console.error('Failed to load MIAW Script');
        }
    } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
    }
}

function launchChat() {
    sessionStorage.setItem('messagingStartCheck', 'YES');
    initEmbeddedMessaging();

    setTimeout(() => {
        if (typeof embeddedservice_bootstrap !== 'undefined' && embeddedservice_bootstrap.utilAPI) {
            embeddedservice_bootstrap.utilAPI.launchChat().catch((error) => {
                // Error while launching chat
                console.error('Failed to launch chat:', error);

                $(chatButtonWrapper).hide();

                if (livechatModal.length > 0) {
                    modal.public.openModal();
                }
            });
        } else {
            // Chat is offline
            console.error('embeddedservice_bootstrap utilAPI is not available');

            $(chatButtonWrapper).hide();

            if (livechatModal.length > 0) {
                modal.public.openModal();
            }
        }
    }, 2000);
}
